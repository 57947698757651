import React, { Component } from "react";
import ServiceBox from "../components/ServiceBox";
import ServiceData from "../services.json";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
class HomePage extends Component {
  getPrivacyNotice = () => {
    // using Java Script method to get PDF file
    fetch("Harmer-Therapy-Privacy-Notice.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Harmer-Therapy-Privacy-Notice.pdf";
        alink.click();
      });
    });
  };

  getTCNotice = () => {
    // using Java Script method to get PDF file
    fetch("Harmer-TandC.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Harmer-TandC.pdf";
        alink.click();
      });
    });
  };
  render() {
    return (
      <>
        <h1 style={{ color: "#1C3444", lineWidth: "3000%" }}>
          Harmer SLT Services and Costs
        </h1>{" "}
        <br />
        <p style={{ fontSize: "24px" }}>I do not cover Tribunal cases</p>
        <p style={{ fontSize: "24px" }}>
          Costs are reviewed annually, each September, and are subject to change
        </p>
        <button onClick={this.getPrivacyNotice}>
          Please click here to download my Privacy Notice
        </button>
        <div style={{ margin: "10px 0" }} />
        <button onClick={this.getTCNotice}>
          Please click here to download my Terms and Conditions
        </button>
        <br />
        <p> </p>
        <Row
          xs={1}
          sm={1}
          md={1}
          lg={2}
          xl={2}
          xxl={3}
          className="g-4 justify-content-center"
        >
          {Object.keys(ServiceData).map((key, i) => (
            <Col>
              <ServiceBox
                title={ServiceData[key]["title"]}
                content={ServiceData[key]["content"]}
                price={ServiceData[key]["price"]}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  }
}

export default HomePage;
