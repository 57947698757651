import React, { Component } from "react";

class HomePage extends Component {
  render() {
    return (
      <>
        <h1 style={{ color: "#1C3444", lineWidth: "3000%" }}>Contact Me</h1>
        <br />
        <h1 style={{ color: "#1C3444", fontSize: "28px" }}>
          Phone number: 07816 427034
        </h1>
        <h1 style={{ color: "#1C3444", fontSize: "28px" }}>
          Email: sarah@harmer-speech-language-therapy.co.uk
        </h1>
      </>
    );
  }
}

export default HomePage;
