import React, { Component } from "react";

import AboutPage from "./AboutPage";
import ServicesPage from "./ServicesPage";
import TestimonialsPage from "./TestimonialsPage";
import ContactPage from "./ContactPage";

class HomePage extends Component {
  render() {
    return (
      <>
        <AboutPage />
        <br />

        <ServicesPage />
        <br />
        <TestimonialsPage />
        <br />
        <ContactPage />
      </>
    );
  }
}

export default HomePage;
